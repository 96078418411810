import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from "axios";
import store from '@/store'
import router from '@/router';

export default class ApiServices {
  public api!: AxiosInstance;
  protected token!: string;
  protected token_type!: string;
  protected expires_in!: number;
  protected company!: number;
  protected store!: any;
  protected base: string = process.env.VUE_APP_API_URL;
  
  public constructor () {
    this.api = axios.create({ baseURL: this.base });
    this.requestInterceptor();
    this.responseInterceptor();
  }
  
  private requestInterceptor = () => {
    this.api.interceptors.request.use(this.handleRequest); 
  }

  private responseInterceptor = () => {
    this.api.interceptors.response.use(response => {
      if (response.data) {
        if (response.data.authorization) {
          const authorization = response.data.authorization;
          
          this.token = authorization['access_token'];
          this.token_type = authorization['token_type'];
          this.expires_in = authorization['expires_in'];

          store.dispatch('setAuthorization', authorization);
        }
        if (response.data.user) {
          store.dispatch('setUser', response.data.user);
        }
        if (response.data.company) {
          store.dispatch('updateCompany', response.data.company);
        }
      }
      
      return Promise.resolve(response.data);
    }, this.handleError);
  }

  private handleRequest = (config: any) => {
    if (store.state.authorization) {
      this.token = store.state.authorization['access_token'];
      this.token_type = store.state.authorization['token_type'];
      this.expires_in = store.state.authorization['expires_in'];
      this.company = Number(store.state.companyId);

      config.headers['Authorization'] = `${this.token_type} ${this.token}`;
      config.headers['Company'] = this.company;
    }

    return config;
  }

  private handleError = async (error: AxiosError) => {
    const originalConfig: AxiosRequestConfig = error.config as AxiosRequestConfig;
    if (error.code == 'ERR_NETWORK') {
      alert('Oops! Network Error.');
    }
    if (this.token && error.response?.status === 401) {
      const check = await this.api.get('/oauth/check');
      if (! check['is_valid']) {
        store.dispatch('clearStorage');
        store.dispatch('setRedirectUrl', router.currentRoute.value.fullPath);
        router.push('/login');
      }
      else {
        store.dispatch('setAlertMessage', { type: 'error', title: 'Authorization Error', messages: [ "You are not authorized to perform this operation." ] });
      }
    }
    return Promise.reject(error);
  }

}