export class CurrencyModel {
  id = 'USD';
  name = '';
  data: CurrencyDataModel = new CurrencyDataModel();

  constructor(data: any = {}) {
    if (data == null) return;
    
    this.id = data.id || 'USD';
    this.name = data.name || '';
    this.data = new CurrencyDataModel(data.data);
  }
}

export class CurrencyDataModel {
  name =  '';
  symbol = '';

  constructor(data: any = {}) {
    if (data == null) return;
    
    this.name = data.name || '';
    this.symbol = data.symbol || '';
  }
}
