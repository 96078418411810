<template>
  <!-- <div class="app-loading" v-if="!ready" :class="{loaded: loaded}">
    <div class="container">
      <div class="text"><svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-text"></use></svg></div>
      <div class="logo"><svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-image"></use></svg></div>
    </div>
    <div class="loader"></div>
  </div>
  <router-view v-if="loaded" /> -->
  <router-view />
</template>

<style scoped>@import '@/assets/styles/common.css';</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { VariableServices } from './services/Variables';

@Options({
  computed: {
    // variableServices () { return VariableServices.getInstance() },
    // init() { return this.$store.state.init; },
    // variables() { return this.$store.state.variables; }
  },
  async created() {
    // let response = await this.variableServices.init();
    // setTimeout(() => {
    //   response.data.forEach(async (item: any) => {
    //     await this.variableServices.get(item.key);
    //     if (this.variables.length == this.init.length) {
    //       this.loaded = true;
    //       setTimeout(() => this.ready = true, 500);
    //     }
    //   });
    // }, 1750);
  },
})

export default class App extends Vue {
  loaded!: boolean;
  ready!: boolean;
  variableServices!: VariableServices;
  
  data() {
    return {
      loaded: false,
      ready: false,
    }
  }
}
</script>

<style scoped>
/* ".app-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  transition: opacity .5s;
  z-index: 2;
}
.app-loading.loaded {
  opacity: 0;
}
.app-loading .container {
  display: flex;
  align-items: center;
  gap: 10px;
  filter: grayscale(1);
  animation: colorize 1.5s ease 0.25s forwards;
}
.app-loading .container .text {
  width: 250px;
  height: 100px;
}
.app-loading .container .logo {
  position: relative;
  width: 60px;
  height: 100px;
  left: -100%;
  animation: load-start 1.5s ease 0.25s forwards;
  background: linear-gradient(to right, transparent, var(--white));
}
.app-loading .container svg {
  width: 100%;
  height: 100%;
}
.app-loading .container .logo:after {
  position: absolute;
  content: '';
  width: 250px;
  height: 100%;
  background-color: var(--white);
}
.app-loading .loader {
  position: relative;
  width: 240px;
  height: 6px;
  border-radius: 3px;
  background-color: var(--neutral300);
  overflow: hidden;
  opacity: 0;
  animation: show .5s 1.5s forwards;
}
.app-loading .loader:after {
  position: absolute;
  content: '';
  width: 0;
  height: 100%;
  background-color: #00B7C6;
  animation: progress 15s ease 1.5s forwards;
}" */
</style>