<template>
  <app-layout>
    <template v-slot:page>
      <router-view />
    </template>
  </app-layout>
</template>

<script lang="ts">
import AppLayout from '@/components/layouts/App.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    'app-layout': AppLayout
  }
})

export default class Index extends Vue {
  data() {
    return {}
  }
}
</script>