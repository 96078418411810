<template>

  <div class="header">

    <section>
      <!-- <div class="breadcrump">
        <template v-for="(crump, index) of breadcrumps" :key="index" >
          <template v-if="index != breadcrumps.length - 1">
            <router-link :to="crump.path">{{crump.name}}</router-link>
          </template>
        </template>
      </div> -->
      <div class="page-title" :badge="badge">{{ breadcrumps.at(-1)?.name ?? 'Welcome' }}</div>
    </section>

    <section class="nav" :class="{ active: menuIsShow }">
      <div class="item">
        <svg class="search" @click="$router.go(0);"><use xlink:href="@/assets/images/sprites/common-sprite.svg#search-icon"></use></svg>
      </div>
      <div class="item">
        <svg class="notification" @click="toggleMenu('notification')"><use xlink:href="@/assets/images/sprites/common-sprite.svg#notification-icon"></use></svg>
      </div>
      <div class="item">
        <div class="user" @click="toggleMenu('profile')">
          <photo-viewer class="photo" v-if="user.photo" :source="user.photo" />
          <span v-else>{{ getUserPhotoPlaceholder() }}</span>
        </div>
      </div>
      <div class="item">
        <svg class="logout" @click="logout"><use xlink:href="@/assets/images/sprites/common-sprite.svg#logout-icon"></use></svg>
      </div>
      
      <template v-for="(menu, index) in menus" :key="index">
        <template v-if="menu.show">
          <div class="toggle-menu" :class="{ 'closing': !menu.show }">
            <component :is="menu.component" @close="toggleMenu(menu.type)" />
          </div>
        </template>
      </template>
    </section>
    
  </div>

  <div class="overlay" v-if="menuIsShow"></div>

  <alert-message class="alert" v-if="alertMessage.messages" :type="alertMessage.type" :title="alertMessage.title" :messages="alertMessage.messages" />

</template>

<script lang="ts">
import { markRaw } from "vue";
import { Options, Vue } from "vue-class-component";
import Notification from "@/components/shared/Notification.vue";
import Profile from "@/components/shared/Profile.vue";
import AlertMessage from "./AlertMessage.vue";
import { User } from "@/models/commons/user";
import PhotoViewer from "./PhotoViever.vue";


@Options({
  components: {
    'alert-message': AlertMessage,
    'photo-viewer': PhotoViewer
  },
  computed: {
    breadcrumps() {
      return this.$route.matched
        .filter((route) => { return route.path != '/' && route.meta.title })
        .map((route) => { return { path: route.path, name: route.meta.title } })
    },
    badge() { return this.$route.meta.badge ?? null; },
    alertMessage() { return this.$store.state.alertMessage },
    user(): User { return new User(this.$store.state.user) },
  },
  methods: {
    getUserPhotoPlaceholder(): string {
      if (! this.user.id) return '';
      return (this.user.first_name[0] + this.user.last_name[0]).toUpperCase();
    },
    logout() {
      this.$router.push('/login');
      this.$store.dispatch('clearStorage');
    },
    getBadgeValue() {
      if (this.$route.path.includes('/shipments/new')) return 'New';
      return null;
    },
    toggleMenu (type: string) {
      if (!type) return;
      const menu = this.menus.find((item: menu) => item.type == type);
      if (!menu) return;
      menu.show = !menu.show;
      this.menuIsShow = !this.menuIsShow;
    }
  }
})

export default class Headbar extends Vue {
  breadcrumps!: crump[];
  badge!: string;
  logout!: any;
  getBadgeValue!: any;
  getUserPhotoPlaceholder!: any;
  alertMessage!: AlertMessage;
  user!: User;

  toggleMenu!: any;
  menus!: menu[];
  menuIsShow!: boolean;
  

  data() {
    return {
      menuIsShow: false,
      menus: [
        { type: 'notification', show: false, component: markRaw(Notification) },
        { type: 'profile', show: false, component: markRaw(Profile) }
      ],
    }
      
  }
}

export interface crump { path: string, name: string , badge: string }
export interface menu { type: string, show: boolean, component: any }
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 98;
  overflow-y: auto;
  animation: blur ease .5s forwards;
}
.header {
  display: flex;
  position: relative;
  padding: 20px 40px;
  z-index: 99;
}
/* .header:after {
  content: "";
  position: absolute;
  width: calc(100% - 80px);
  height: 10px;
  top: 100%;
  left: 40px;
  background: linear-gradient(to bottom, #F4F7FE, transparent);
} */
.header section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.header .breadcrump {
  display: flex;
  gap: 2px;
}
.header .breadcrump * {
  position: relative;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--neutral600);
  text-decoration: none;
}
.header .page-title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--neutral800);
}
.header .page-title[badge]::after {
  content: attr(badge);
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: var(--primary600);
  background-color: var(--neutral100);
  border: 1px solid var(--primary200);
  padding: 2px 6px;
  border-radius: 3px;
}
.header .nav {
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  height: 50px;
  border-radius: 25px;
  padding: 0 20px;
  background-color: var(--white);
  stroke: var(--neutral700);
  border: 1px solid transparent;
  box-shadow: 0px 0px 20px -5px rgba(65, 76, 102, 0.25);
  transition: all .25s;
}
.header .nav.active {
  border: 1px solid var(--neutralCadetBlueCrayola);
}
.header .nav svg {
  position: relative;
  width: 22px;
  height: 22px;
  cursor: pointer;
  transition: all ease-in-out .25s;
}
.header .nav .user {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid var(--success600);
  background-color: var(--neutral100);
  font-size: 10px;
  cursor: pointer;
}
.header .nav .user::after {
  position: absolute;
  content: '';
  right: -1px;
  bottom: -1px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--success600);
}
.header .nav .user .photo {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 50%;
  background-color: var(--white);
  overflow: hidden;
}
.header .nav svg:hover,
.header .nav .active svg {
  stroke: var(--primary600);
}
.header .toggle-menu {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  background-color: var(--white);
  border-radius: 10px;
  border: 1px solid var(--neutralCadetBlueCrayola);
  box-shadow: 0px 0px 20px -5px rgba(65, 76, 102, 0.25);
  overflow: hidden;
  z-index: 1;
}
.alert {
  margin: 5px 40px;
}
@media screen and (max-width: 800px) {
  .header {
    padding: 10px;
    transition: all ease-in-out .25s;
  }
}
</style>