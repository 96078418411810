<template>
  <div class="container" :class="{ fold: isFold }" v-click-away="close" @keyup.esc="close">

    <div class="profile" @click="isFold = !isFold">
      <div class="image">
        <photo-viewer class="photo" v-if="user.photo" :source="user.photo" />
        <span v-else>{{ getUserPhotoPlaceholder() }}</span>
      </div>
      <div class="info">
        <span class="name">{{ user.first_name }} {{ user.last_name }}</span>
        <span class="email">{{ user.email }}</span>
      </div>
      <svg class="arrow"><use xlink:href="@/assets/images/sprites/common-sprite.svg#down-arrow-icon"></use></svg>
    </div>

    <div class="companies">

      <div v-for="(_company, index) in companies" :key="index" class="item" :class="{ active: _company.id == company.id }" @click="setCompany(_company)">
        <div class="image">
          <photo-viewer class="photo" v-if="_company.photo" :source="_company.photo" />
          <span v-else>{{ getCompanyPhotoPlaceholder(_company) }}</span>
        </div>
        <div class="info">
          <span>{{ _company.name }}</span>
        </div>
      </div>

      <div v-for="(company, index) in pendingCompanies" :key="index" class="item pending">
        <div class="image">
          <photo-viewer class="photo" v-if="company.photo" :source="company.photo" />
          <span v-else>{{ getCompanyPhotoPlaceholder(company) }}</span>
        </div>
        <div class="info">
          <span>{{ company.name }}</span>
          <span class="badge">PENDING</span>
        </div>
      </div>

      <div class="item new" @click="$router.push('/register/company')">
        <div class="image">
          <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#company-icon"></use></svg>
        </div>
        <div class="info">
          <span>Create new company</span>
        </div>
      </div>

    </div>

  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { User } from "@/models/commons/user";
import PhotoViewer from "./PhotoViever.vue";

@Options({
  components: {
    'photo-viewer': PhotoViewer
  },
  computed: {
    user(): User { return new User(this.$store.state.user) },
    companies() { return this.$store.state.companies },
    company() { return this.$store.getters.company },
    pendingCompanies() { return this.$store.state.pendingCompanies },
  },
  methods: {
    setCompany(company) {
      this.$store.dispatch('setCompanyId', company.id);
      this.$router.go(this.$router.currentRoute);
    },
    getUserPhotoPlaceholder(): string {
      if (! this.user.id) return '';
      return (this.user.first_name[0] + this.user.last_name[0]).toUpperCase();
    },
    getCompanyPhotoPlaceholder(company): string {
      if (! company.id) return '';
      return (company.name[0] + company.name[1]).toUpperCase();
    },
    close() {
      this.$emit('close');
    },
  },
})

export default class Notification extends Vue {
  user!: User;
  company!: any;
  companies!: any[];
  pendingCompanies!: any[];
  isFold!: boolean;
  close!: any;
  setCompany!: any;
  getUserPhotoPlaceholder!: any;
  getCompanyPhotoPlaceholder!: any;

  // services

  data() {
    return {
      isFold: true,
    }
  }
}

</script>

<style scoped>

.container {
  width: 420px;
  display: flex;
  flex-direction: column;
}
.container .profile {
  padding: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.container .profile .image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: var(--neutral100);
  border: 1px solid var(--success600);
  stroke: var(--neutral600);
  border-radius: 50%;
  font-size: 15px;
}
.container .profile .image:after {
  position: absolute;
  content: '';
  right: 2px;
  bottom: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--success600);
}
.container .profile .image .photo {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 50%;
  background-color: var(--white);
  overflow: hidden;
}
.container .profile .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.container .profile .info .name {
  color: var(--neutral800);
  font-size: 14px;
  letter-spacing: -0.28px;
}
.container .profile .info .email {
  color: var(--neutral400);
  font-size: 12px;
  font-family: Poppins;
  letter-spacing: -0.24px;
}
.container .profile .arrow {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  stroke: var(--neutral800);
}
.container:not(.fold) .profile .arrow {
  transform: rotate(180deg);
}

.container.fold .companies {
  height: 0;
}
.container .companies:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 10px;
  box-shadow: inset 0px 5px 10px -5px rgba(65, 76, 102, 0.25);
  z-index: 1;
}
.container .companies .item {
  padding: 10px 25px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.container .companies .item.active {
  background-color: var(--neutral150);
}
.container .companies .item:hover {
  background-color: var(--neutral200);
}
.container .companies .item.pending {
  background-color: var(--neutral100);
  cursor: default;
}
.container .companies .item.pending > * {
  opacity: .5;
}
.container .companies .item .image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: var(--neutral100);
  border: 1px solid var(--primary600);
  stroke: var(--neutral600);
  border-radius: 50%;
  font-size: 12px;
}
.container .companies .item .image svg {
  width: 18px;
  height: 18px;
  stroke-width: 1.5px;
}
.container .companies .item .image .photo {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 50%;
  background-color: var(--white);
  overflow: hidden;
}
.container .companies .item .info {
  display: flex;
  align-items: center;
  flex: 1;
  color: var(--neutral800);
  font-size: 13px;
  letter-spacing: -0.28px;
}
.container .companies .item .info .badge {
  border-radius: 4px;
  font-size: 11px;
  padding: 3px 5px;
  margin-left: auto;
}
.container .companies .item.pending .info .badge {
  border: 1px solid var(--warning600);
  background-color: var(--warning100);
  color: var(--warning600);
}

</style>