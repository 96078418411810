import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueClickAway from "vue3-click-away";

createApp(App)
    .use(router)
    .use(store)
    .use(VueGoogleMaps, { load: { key: 'AIzaSyDQO-LnSV59Wdydob9SOHrWx5_dzriGdFk', libraries: "places" } })
    .use(VueClickAway)
    .mount('#app')
