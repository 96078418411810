import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#app-logo-text'
const _hoisted_2 = _imports_0 + '#app-logo-image'
const _hoisted_3 = { class: "main" }
const _hoisted_4 = { class: "nav" }
const _hoisted_5 = { class: "icon" }
const _hoisted_6 = ["xlink:href"]
const _hoisted_7 = {
  key: 0,
  class: "sub-container"
}
const _hoisted_8 = { class: "nav" }
const _hoisted_9 = { class: "icon" }
const _hoisted_10 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar", { min: _ctx.sub }])
  }, [
    _createElementVNode("div", _hoisted_3, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "logo" }, [
        _createElementVNode("svg", { class: "app-logo-text" }, [
          _createElementVNode("use", { "xlink:href": _hoisted_1 })
        ]),
        _createElementVNode("svg", { class: "app-logo-image" }, [
          _createElementVNode("use", { "xlink:href": _hoisted_2 })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nav, (route, routeIndex) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: routeIndex }, [
            _createVNode(_component_router_link, {
              to: route.path,
              class: _normalizeClass([route.class, { active: _ctx.$route.matched[0].path == route.path }])
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock("svg", _hoisted_5, [
                  _createElementVNode("use", {
                    "xlink:href": require('@/assets/images/sprites/common-sprite.svg') + route.icon
                  }, null, 8, _hoisted_6)
                ])),
                _createElementVNode("span", null, _toDisplayString(route.name), 1)
              ]),
              _: 2
            }, 1032, ["to", "class"]),
            (route.sub)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["sub", { active: _ctx.$route.matched[0].path == route.path }])
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(route.sub, (sub, subIndex) => {
                        return (_openBlock(), _createBlock(_component_router_link, {
                          key: subIndex,
                          to: sub.path,
                          class: _normalizeClass([sub.class, { active: _ctx.$route.path == sub.path }])
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createElementBlock("svg", _hoisted_9, [
                              _createElementVNode("use", {
                                "xlink:href": require('@/assets/images/sprites/common-sprite.svg') + sub.icon
                              }, null, 8, _hoisted_10)
                            ])),
                            _createElementVNode("span", null, _toDisplayString(sub.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["to", "class"]))
                      }), 128))
                    ])
                  ], 2)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ])
  ], 2))
}