<template>
  <div id="login-layout">
    <div class="slider-container">
      <div class="slot">
        <slot name="slider">Slider Container</slot>
      </div>
    </div>
    <div class="form-container">
      <div class="row head">
        <svg class="app-logo"><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-text"></use></svg>
      </div>
      <div class="row title">
        {{ title }}
      </div>
      <div class="row slot">
        <slot name="form">Form Container</slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    title: {type: String}
  }
})

export default class OnboardingLayout extends Vue {
  title!: string;
}
</script>

<style scoped>
#login-layout {
  display: flex;
  width: 100%;
  height: 100%;
  /* background-color: var(--primary700); */
}
.slider-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
}
.slider-container .slot {
  height: 646px;
}
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  /* background-color: var(--white); */
  flex: 1;
}
.form-container .row {
  width: 400px;
  max-width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.form-container .row.slot {
  height: 600px;
  max-height: 100%;
}
.form-container .app-logo {
  width: 100%;
  height: 58px;
}
.form-container .title {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: var(--primary600)
}

@media screen and (max-width: 1024px) {
  .slider-container {
    display: none;
  }
  .form-container {
    height: 100%;
    overflow-y: scroll;
    padding-top: 60px;
    gap: 0;
  }
  .form-container .row.head {
    position: fixed;
    width: 100%;
    height: 60px;
    max-width: inherit;
    top: 0;
    left: 0;
    border-bottom: 1px solid var(--neutral150);
    background-color: var(--white);
    padding: 20px;
  }
  .form-container .app-logo {
    width: 150px;
  }
  .form-container .title {
    font-size: 20px;
  }
}
@media screen and (max-height: 768px) {
  .form-container {
    height: 100%;
    overflow-y: scroll;
  }
}
</style>