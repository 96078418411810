import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#cross-icon'
const _hoisted_2 = _imports_0 + '#link-icon'
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "icon" }
const _hoisted_5 = ["xlink:href"]
const _hoisted_6 = { class: "message" }
const _hoisted_7 = {
  key: 0,
  href: "#",
  class: "see-more"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", _ctx.type])
  }, [
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createElementBlock("svg", _hoisted_4, [
        _createElementVNode("use", {
          "xlink:href": require('@/assets/images/sprites/common-sprite.svg') + _ctx.icons[_ctx.type]
        }, null, 8, _hoisted_5)
      ])),
      _createElementVNode("span", null, _toDisplayString(_ctx.title ?? _ctx.titles[_ctx.type]), 1),
      (_openBlock(), _createElementBlock("svg", {
        class: "close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("use", { "xlink:href": _hoisted_1 }, null, -1)
      ])))
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, index) => {
        return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(message), 1))
      }), 128))
    ]),
    (_ctx.more)
      ? (_openBlock(), _createElementBlock("a", _hoisted_7, _cache[2] || (_cache[2] = [
          _createElementVNode("span", null, "SEE MORE", -1),
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_2 })
          ], -1)
        ])))
      : _createCommentVNode("", true)
  ], 2))
}