import { renderSlot as _renderSlot, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.status)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["overlay", { closing: _ctx.closing }]),
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "content",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
            onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["esc"]))
          }, [
            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
          ], 32)
        ])
      ], 2))
    : _createCommentVNode("", true)
}