import ApiServices from "./Api";

export class AuthServices extends ApiServices {
  private static instance?: AuthServices;

  public static getInstance(): AuthServices {
    if (! this.instance) this.instance = new AuthServices();
    return this.instance;
  }

  public connectEmail = (info: connectEmailInfo) => this.api.post('/oauth/connect/email', info);
  public connectGoogle = (info: connectGoogleInfo) => this.api.post('/oauth/connect/google', info);
  public refresh = () => this.api.get('/oauth/refresh');
  public me = () => this.api.get('/oauth/me');
  
}

export interface connectEmailInfo { email: string; password: string; }
export interface connectGoogleInfo { code: string; }