export class TimeZoneModel {
  id = 'UTC';
  name = '';

  constructor(data: any = {}) {
    if (data == null) return;
    
    this.id = data.id || 'UTC';
    this.name = data.name || '';
  }
}
