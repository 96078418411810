<template>
  <template v-if="!ready">
    <div class="app-loading" :class="{loaded: loaded}">
    <div class="container">
      <div class="text"><svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-text"></use></svg></div>
      <div class="logo"><svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-image"></use></svg></div>
    </div>
    <div class="loader"></div>
    </div>
  </template>
  <template v-if="loaded">
    <div v-if="company" id="app-layout">
      <sidebar-component />
      <div class="main">
        <headbar-component />
        <div class="container">
          <slot name="page">Page Container</slot>
        </div>
      </div>
    </div>
    <!-- <modal-component>
      <template v-slot:content>
        <response-component />
      </template>
    </modal-component> -->
    <modal-component :show="!company">
      <template v-slot:content>
        <companychooser-component />
      </template>
    </modal-component>
  </template>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Headbar from "../shared/Headbar.vue";
import Sidebar from "../shared/Sidebar.vue";
import Modal from "../shared/Modal.vue";
import CompanyChooser from '../shared/CompanyChooser.vue';
import Response from '../shared/Response.vue';
import { VariableServices } from '@/services/Variables';
import { AuthServices } from "@/services/Auth";
import { AccountsServices } from "@/services/Accounts";
import { User } from "@/models/commons/user";
import { InvitationServices } from "@/services/Invitation";

@Options({
  components: {
    'sidebar-component': Sidebar,
    'headbar-component': Headbar,
    'modal-component': Modal,
    'companychooser-component': CompanyChooser,
    'response-component': Response,
  },
  props: {
    title: {type: String}
  },
  watch: {
    user(n, o) {
      if (!n && o) this.$router.push('/login');
    }
  },
  computed: {
    variableServices () { return VariableServices.getInstance() },
    init() { return this.$store.state.init; },
    variables() { return this.$store.state.variables; },
    user(): User { return new User(this.$store.state.user) },
    companies() { return this.$store.state.companies },
    company() { return this.$store.getters.company },
    pendingCompanies() { return this.$store.state.pendingCompanies },
  },
  async created() {
    if (this.$route.matched[0].path == '/invitation/:process*') {
      const process = this.$route.params.process;
      if (process[0] == 'approve' && process[1]) {
        if (this.user.is_complete) await this.invitationService.approve({ data: process[1] });
        else {
          await this.invitationService.check({ data: process[1] }).then((response: any) => {
            const invitation = response.data;
            this.$store.dispatch('setInvitation', { invitation: invitation, process: process[1] });
            if (invitation.invited) this.$router.push({ path: '/login', hash: `#!${invitation.email}` });
            else this.$router.push({ path: '/register', hash: `#!${invitation.email}` });
          }).catch((error: any) => {
            this.$store.dispatch('setAlertMessage', { type: 'error', title: 'Approve Error', messages: [ error.response.data.message ] });
          });
        }
      }
      //this.$router.push('/register');
      this.loaded = true;
      return;
    }
    
    await this.variableServices.init();
    this.init.forEach(async (item: any) => await this.variableServices.get(item.key) );
    
    await this.authServices.me().catch(error => {
      if (error.response.status == 401) {
        this.$store.dispatch('clearStorage');
        this.$router.push('/login');
      }
    });

    const invitation = this.$store.state.invitation;
    if (invitation && this.user.is_complete) {
      await this.invitationService.approve({ data: invitation.process });
      this.$store.dispatch('removeInvitation');
    }

    if (this.user.id) {
      await this.accountsServices.compaines().then((response: any) => {
        this.$store.dispatch('setCompanyRoles', response.roles);
        this.$store.dispatch('setCompanies', response.data);
        this.$store.dispatch('setPendingCompanies', response.pendings);
      });
      if (! this.user.is_complete) return this.$router.push('/register/profile');
      if (! this.companies.length && ! this.pendingCompanies.length) return this.$router.push('/register/company');
    }
    
    this.loaded = true;
    setTimeout(() => {
      this.ready = true;
      const redirectUrl = this.$store.state.redirectUrl;
      if (redirectUrl) {
        this.$store.dispatch('removeRedirectUrl');
        this.$router.push(redirectUrl);
      }
    }, 500);
    
  },
  methods: {
    getCompanyPhotoPlaceholder(company): string {
      if (! company.id) return '';
      return (company.name[0] + company.name[1]).toUpperCase();
    },
  }
})

export default class AppLayout extends Vue {
  loaded!: boolean;
  ready!: boolean;
  title!: string;
  user!: any;
  companies!: [any];
  company!: any;
  getCompanyPhotoPlaceholder!: any;
  authServices!: AuthServices;
  accountsServices!: AccountsServices;
  invitationService!: InvitationServices;
  
  data() {
    return {
      loaded: false,
      ready: false,
      authServices: AuthServices.getInstance(),
      accountsServices: AccountsServices.getInstance(),
      invitationService: InvitationServices.getInstance()
    }
  }
}
</script>

<style scoped>
#app-layout {
  display: flex;
  height: 100%;
}
.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  /* position: relative; */
}
.main > .container {
  position: relative;
  height: 100%;
  overflow: auto;
  padding: 10px 40px 40px 40px;
}

.app-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: var(--white); */
  transition: opacity .5s;
  z-index: 2;
}
.app-loading.loaded {
  opacity: 0;
}
.app-loading .container {
  display: flex;
  align-items: center;
  gap: 10px;
  filter: grayscale(1);
  animation: colorize 1.5s ease 0.25s forwards;
}
.app-loading .container .text {
  width: 0%;
  overflow: hidden;
  animation: load-start-text 1.5s ease 0.25s forwards;
}
.app-loading .container .text svg {
  width: 250px;
  height: 100px;
}
.app-loading .container .logo {
  width: 60px;
  min-width: 60px;
  height: 100px;
}
.app-loading .container .logo svg {
  position: relative;
  left: -50%;
  animation: load-start-logo 1.5s ease 0.25s forwards;
  animation-delay: .75s;
}
.app-loading .container svg {
  width: 100%;
  height: 100%;
}
.app-loading .container .logo:after {
  position: absolute;
  content: '';
  width: 250px;
  height: 100%;
}
.app-loading .loader {
  position: relative;
  width: 240px;
  height: 6px;
  border-radius: 3px;
  background-color: var(--neutral300);
  overflow: hidden;
  opacity: 0;
  animation: show .5s 1.5s forwards;
}
.app-loading .loader:after {
  position: absolute;
  content: '';
  width: 0;
  height: 100%;
  background-color: #00B7C6;
  animation: progress 15s ease 1.5s forwards;
}

@media screen and (max-width: 800px) {
  .main > .container {
    padding: 10px;
    transition: all ease-in-out .25s;
  }
}
</style>