<template>
  <onboarding-layout title="Sign In">
    <template v-slot:slider>
      <slider-component class="slider" :slider="slider" pagerPosition="left" />
      <img loading="lazy" src="@/assets/images/sliders/background.png" />
    </template>
    <template v-slot:form>
      <router-view />
      <div class="form-row create">
        <span>Not registered yet ?</span>
        <router-link to="/register">Create an Account</router-link>
      </div>
    </template>
  </onboarding-layout>
</template>

<script lang="ts">
import OnboardingLayout from '@/components/layouts/Onboarding.vue';
import Slider from '@/components/shared/Slider.vue';
import { Options, Vue } from 'vue-class-component';
import { Router, useRouter } from 'vue-router';

@Options({
  components: {
    'onboarding-layout': OnboardingLayout,
    'slider-component': Slider,
  },
  created() {
    //console.log(this.router.currentRoute);
  }
})

export default class LoginIndex extends Vue {
  slider!: [any];
  router!: Router;

  data() {
    return {
      router: useRouter(),
      slider: [
        { id: 1, title: "Did you see our new modules ?", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { id: 2, title: "Did you see our new modules ?", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { id: 3, title: "Did you see our new modules ?", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit." }
      ]
    }
  }
}
</script>

<style scoped>
.slider-container .slider {
  width: 450px;
}
.slider-container img {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 100%;
  height: 50%;
  object-fit: contain;
  object-position: bottom;
  z-index: 0;
}

:deep(.form-container form) {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
}
:deep(.form-container h1) {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: var(--neutral800);
}
:deep(.form-container h2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--neutral800);
}
:deep(.form-container button) {
  width: 100%;
}
:deep(.form-container .back) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto 0 0 0;
}

.form-container .create {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.form-container .create span {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: var(--neutral800);
}
</style>