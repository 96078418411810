import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#app-logo-text'
const _hoisted_2 = {
  key: 0,
  class: "container"
}
const _hoisted_3 = { class: "nav" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.$route.matched.length == 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("svg", { class: "logo" }, [
          _createElementVNode("use", { "xlink:href": _hoisted_1 })
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("a", {
            href: `//app.${_ctx.baseDomain}/login`
          }, "Sign In", 8, _hoisted_4),
          _createVNode(_component_router_link, { to: "/terms-of-service" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Terms Of Service")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/privacy-policy" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Privacy Policy")
            ])),
            _: 1
          })
        ])
      ]))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}