import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#app-logo-text'
const _hoisted_2 = { id: "login-layout" }
const _hoisted_3 = { class: "slider-container" }
const _hoisted_4 = { class: "slot" }
const _hoisted_5 = { class: "form-container" }
const _hoisted_6 = { class: "row title" }
const _hoisted_7 = { class: "row slot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_2, [
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "slider", {}, () => [
          _cache[0] || (_cache[0] = _createTextVNode("Slider Container"))
        ], true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "row head" }, [
        _createElementVNode("svg", { class: "app-logo" }, [
          _createElementVNode("use", { "xlink:href": _hoisted_1 })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_7, [
        _renderSlot(_ctx.$slots, "form", {}, () => [
          _cache[1] || (_cache[1] = _createTextVNode("Form Container"))
        ], true)
      ])
    ])
  ]))
}