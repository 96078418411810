<template>
    <div class="ntf-container" v-click-away="close" @keyup.esc="close">
      <div class="ntf-header">
        <div class="ntf-header-top">
          <h3>Notifications</h3>
          <span class="ntf-header-tr">
            <span class="marked-all" @click="markAllAsRead">Mark all as read</span>
            <svg class="close-icon" @click="close" >
              <use xlink:href="@/assets/images/sprites/common-sprite.svg#close-icon"></use>
            </svg>
          </span>
        </div>
        <ul class="ntf-header-btm">
          <li v-for="(item, index) in tabs" :key="index" :class="{ 'selected': item.key === selectedTab }" @click="selectedTab = item.key">
            <svg v-if="item.icon" class="icon">
                <use :xlink:href=" require('@/assets/images/sprites/common-sprite.svg') + item.icon"></use>
            </svg>
            {{ item.name }}
            <span class="badge">2</span>
          </li>
        </ul>
      </div>

      <div class="day">TODAY</div>
      <ul class="notify-items">
        <li class="notify-item" v-for="(item, index) in 4" :key="index" :class="{ 'marked': todayMarkedItems.includes(index) }" @click="toggleMarked(index, 'todayMarkedItems')">
          <span class="chat-status">
            <svg class="icon" v-if="todayMarkedItems.includes(index)">
              <use xlink:href="@/assets/images/sprites/common-sprite.svg#checked-mark"></use>
            </svg>
            <svg class="icon" v-else>
              <use xlink:href="@/assets/images/sprites/common-sprite.svg#info-icon"></use>
            </svg>
          </span>
          <div class="notify-info">
            <span class="notify-title">Kanar LLC created new</span>
            <span class="notify-time">10min ago</span>
          </div>
          <div class="category">
            <a>Quote Request #124</a>
          </div>

          <div class="request">
            <svg class="icon" >
              <use xlink:href="@/assets/images/sprites/common-sprite.svg#check"></use>
            </svg>
            <svg class="icon" >
              <use xlink:href="@/assets/images/sprites/common-sprite.svg#close-outlied"></use>
            </svg>
          </div>
        </li>
      </ul>

      <div class="day">YESTERDAY</div>
      <ul class="notify-items">
        <li class="notify-item" v-for="(item, index) in 2" :key="index" :class="{ 'marked': yesterdayMarkedItems.includes(index) }" @click="toggleMarked(index, 'yesterdayMarkedItems')">
          <span class="chat-status">
            <svg class="icon" v-if="yesterdayMarkedItems.includes(index)">
              <use xlink:href="@/assets/images/sprites/common-sprite.svg#checked-mark"></use>
            </svg>
            <svg class="icon" v-else>
              <use xlink:href="@/assets/images/sprites/common-sprite.svg#info-icon"></use>
            </svg>
          </span>
          <div class="notify-info">
            <span class="notify-title">Kanar LLC created new</span>
            <span class="notify-time">20.12.2022 - 18:22</span>
          </div>
          <div class="category">
            <a>Quote Request #124</a>
          </div>

          <div class="request">
            <svg class="icon" >
              <use xlink:href="@/assets/images/sprites/common-sprite.svg#check"></use>
            </svg>
            <svg class="icon" >
              <use xlink:href="@/assets/images/sprites/common-sprite.svg#close-outlied"></use>
            </svg>
          </div>
        </li>
      </ul>
      <div class="actions">
        <button class="clear-all btn secondary" @click="clearAll">Clear All</button>
      </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {},
  watch: {},
  methods: {
    close() {
      this.$emit('close');
    },
    toggleMarked(index, markedItemsArray) {
      const markedItems = this[markedItemsArray];
      
      if (markedItems.includes(index)) {
        const itemIndex = markedItems.indexOf(index);
        markedItems.splice(itemIndex, 1);
      } else {
        markedItems.push(index);
      }
    },
    markAllAsRead() {
      this.todayMarkedItems = Array.from({ length: 4 }, (_, index) => index);
      this.yesterdayMarkedItems = Array.from({ length: 2 }, (_, index) => index);
    },
    clearAll() {
      console.log("clear all");
    }
  },
})

export default class Notification extends Vue {
  tabs!: any;
  selectedTab!: any;
  todayMarkedItems!: any;
  yesterdayMarkedItems!: any;
  toggleMarked!: any;
  clearAll!: any;
  markAllAsRead!: any;
  close!: any;

  // services

  data() {
    return {
      tabs: [
        { key:"all", name: "All"},
        { key:"shipment", name: "Shipment", icon:"#shipments-icon"},
        { key:"quote_request", name: "Quote Request", icon: "#quotes-icon"},
        { key:"connection", name: "Connection", icon: "#connections-icon"},
      ],
      selectedTab: "all",
      todayMarkedItems: [],
      yesterdayMarkedItems: [],
    }
  }
}

</script>

<style scoped>


.ntf-container {
  width: 620px;
  padding: 25px 0;
  display: flex;
  flex-direction: column;
}

.ntf-header {
  padding: 0 30px;
}

.ntf-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ntf-header-top h3{ 
  color: var(--neutral-charcoal);
  font-size: 18px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.36px;
}
.ntf-header-top .ntf-header-tr {
  display: flex;
  align-items: center;
  gap: 30px;
}
.ntf-header-top .ntf-header-tr .marked-all{
  color: var(--primary600);
  font-size: 14px;
  font-family: Poppins;
  line-height: 30px;
  letter-spacing: -0.28px;
  cursor: pointer;
}
.ntf-header-top .ntf-header-tr svg.close-icon{
  width: 16px;
  height: 16px;
  cursor: pointer;
}


.ntf-header-btm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 512px;
  height: 30px;
  margin-top: 19px;
  margin-bottom: 13px;
}

.ntf-header-btm li {
  color: var(--neutral800);
  font-size: 12px;
  font-family: Poppins;
  line-height: 30px;
  letter-spacing: -0.24px;
  display: flex;
  gap: 3px;
  white-space: nowrap;
  height: 30px;
  align-items: center;
  border-radius: 6px;
  padding: 0 10px;
  cursor: pointer;
}
.ntf-header-btm li .icon{
stroke: var(--neutral800);
}
.ntf-header-btm li:hover,
.ntf-header-btm li.selected {
  background: var(--primary100);
  color: var(--primary600) !important;
}
.ntf-header-btm li:hover .badge,
.ntf-header-btm li.selected .badge {
  background: var(--primary200);
  color: var(--primary600);
}
.ntf-header-btm li:hover .icon,
.ntf-header-btm li.selected .icon {
  stroke: var(--primary600);
}
.ntf-header-btm li .icon {
  height: 16px;
  width: 16px;
}

.ntf-header-btm li .badge {
  border-radius: 2px;
  background: var(--neutral150);
  padding: 0 4px;
  color: var(--neutral600, #666687);
  font-size: 11px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 16px;
}

.ntf-container .day {
  background: var(--neutral150);
  padding: 5px 35px;
  color: var(--neutral800);
  font-size: 14px;
  font-family: Poppins;
  font-weight: 600;
  letter-spacing: -0.28px;
}
.notify-items {
  padding: 23px 35px 20px 35px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.notify-items .notify-item {
  border-radius: 6px;
  border: 1px solid var(--neutral100);
  background: var(--white);
  padding: 10px 20px;
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.notify-items .notify-item.marked {
  background: var(--neutral100);
}

.notify-items .notify-item .chat-status svg {
  width: 32px;
  height: 32px;
  stroke: var(--primary600);
}

.notify-item .notify-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
}

.notify-item .notify-info .notify-title {
  color: var(--neutral800);
  font-size: 14px;
  font-family: Poppins;
  letter-spacing: -0.28px;
}
.notify-item .notify-info .notify-time {
  color: var(--neutral400);
  font-size: 12px;
  font-family: Poppins;
  letter-spacing: -0.24px;
}

.category {
  color: var(--primary600, #0A54FF);
  font-size: 14px;
  font-family: Poppins;
  letter-spacing: -0.28px;
  text-decoration-line: underline;
}

.request {
  display: flex;
  gap: 8px;
  margin-left: auto;
}
.request .icon {
  width: 24px;
  height: 25px;
}

.actions {
  padding-left: 35px;
  padding-right: 36px;

}
.clear-all {
  margin-left: auto;
}

</style>