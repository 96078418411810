<template>

  <div class="modal-container">
    
    <svg style="width: 50%; height: 50px; margin: 0 auto;"><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-text"></use></svg>

    <div class="profile">
      <div class="image">
        <photo-viewer class="photo" v-if="user.photo" :source="user.photo" />
        <span v-else>{{ getUserPhotoPlaceholder() }}</span>
      </div>
      <div class="info">
        <span class="name">{{ user.first_name }} {{ user.last_name }}</span>
        <span class="email">{{ user.email }}</span>
      </div>
    </div>

    <h3>Select a company to proceed.</h3>
    <div class="companies">  
      <div v-for="(company, index) in companies" :key="index" class="item" @click="setCompany(company)">
        <div class="image">
          <photo-viewer class="photo" v-if="company.photo" :source="company.photo" />
          <span v-else>{{ getCompanyPhotoPlaceholder(company) }}</span>
        </div>
        <div class="info">
          <span>{{ company.name }}</span>
        </div>
      </div>

      <div v-for="(company, index) in pendingCompanies" :key="index" class="item pending">
        <div class="image">
          <photo-viewer class="photo" v-if="company.photo" :source="company.photo" />
          <span v-else>{{ getCompanyPhotoPlaceholder(company) }}</span>
        </div>
        <div class="info">
          <span>{{ company.name }}</span>
          <span class="badge">PENDING</span>
        </div>
      </div>

      <div class="item new" @click="$router.push('/register/company')">
        <div class="image">
          <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#company-icon"></use></svg>
        </div>
        <div class="info">
          <span>Create new company</span>
        </div>
      </div>
    </div>

  </div>

</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PhotoViewer from "./PhotoViever.vue";
import { User } from "@/models/commons/user";

@Options({
  components: {
    'photo-viewer': PhotoViewer
  },
  computed: {
    user(): User { return new User(this.$store.state.user) },
    companies() { return this.$store.state.companies },
    pendingCompanies() { return this.$store.state.pendingCompanies },
  },
  methods: {
    setCompany(company) {
      this.$store.dispatch('setCompanyId', company.id)
    },
    getUserPhotoPlaceholder(): string {
      if (! this.user.id) return '';
      return (this.user.first_name[0] + this.user.last_name[0]).toUpperCase();
    },
    getCompanyPhotoPlaceholder(company): string {
      if (! company.id) return '';
      return (company.name[0] + company.name[1]).toUpperCase();
    },
  }
})

export default class CompanyChooser extends Vue {
  user!: User;
  companies!: any[];
  pendingCompanies!: any[];
  setCompany!: any;
  getUserPhotoPlaceholder!: any;
  getCompanyPhotoPlaceholder!: any;

  data() {
    return {}
  }
}
</script>

<style scoped>
.modal-container {
  display: flex;
  flex-direction: column;
  width: 350px;
  gap: 10px;
  padding: 30px 25px;
}

.profile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  
}
.profile:after {
  content: '';
  width: 70%;
  height: .5px;
  background-image: linear-gradient(to right, transparent, var(--neutral300), transparent);
}
.profile .image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  background-color: var(--neutral100);
  border: 1px solid var(--primary600);
  stroke: var(--neutral600);
  border-radius: 50%;
  font-size: 15px;
}
.profile .image .photo {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 50%;
  background-color: var(--white);
  overflow: hidden;
}
.profile .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}
.profile .info .name {
  color: var(--neutral800);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
}
.profile .info .email {
  color: var(--neutral400);
  font-size: 12px;
  font-family: Poppins;
  letter-spacing: -0.24px;
}

h3 {
  font-size: 12px;
  color: var(--neutral600);
  text-align: center;
  padding: 5px 0;
}

.companies {
  position: relative;
  border: 1px solid var(--neutral200);
  border-radius: 8px;
  overflow: hidden;
}
.companies .item {
  padding: 10px 25px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.companies .item + .item {
  border-top: 1px solid var(--neutral200);
}
.companies .item:hover {
  background-color: var(--neutral100);
}
.companies .item.pending {
  background-color: var(--neutral100);
  cursor: default;
}
.companies .item.pending > * {
  opacity: .5;
}
.companies .item .image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: var(--neutral100);
  border: 1px solid var(--primary600);
  stroke: var(--neutral600);
  border-radius: 50%;
  font-size: 12px;
}
.companies .item .image svg {
  width: 18px;
  height: 18px;
  stroke-width: 1.5px;
}
.companies .item .image .photo {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 50%;
  background-color: var(--white);
  overflow: hidden;
}
.companies .item .info {
  display: flex;
  flex: 1;
  align-items: center;
  color: var(--neutral800);
  font-size: 13px;
  letter-spacing: -0.28px;
}
.companies .item .info .badge {
  border-radius: 4px;
  font-size: 11px;
  padding: 3px 5px;
  margin-left: auto;
}
.companies .item.pending .info .badge {
  border: 1px solid var(--warning600);
  background-color: var(--warning100);
  color: var(--warning600);
}

</style>