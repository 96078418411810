import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#close-icon'
const _hoisted_2 = _imports_0 + '#checked-mark'
const _hoisted_3 = _imports_0 + '#info-icon'
const _hoisted_4 = _imports_0 + '#check'
const _hoisted_5 = _imports_0 + '#close-outlied'
const _hoisted_6 = { class: "ntf-header" }
const _hoisted_7 = { class: "ntf-header-top" }
const _hoisted_8 = { class: "ntf-header-tr" }
const _hoisted_9 = { class: "ntf-header-btm" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "icon"
}
const _hoisted_12 = ["xlink:href"]
const _hoisted_13 = { class: "notify-items" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "chat-status" }
const _hoisted_16 = {
  key: 0,
  class: "icon"
}
const _hoisted_17 = {
  key: 1,
  class: "icon"
}
const _hoisted_18 = { class: "notify-items" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "chat-status" }
const _hoisted_21 = {
  key: 0,
  class: "icon"
}
const _hoisted_22 = {
  key: 1,
  class: "icon"
}
const _hoisted_23 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "ntf-container",
    onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["esc"]))
  }, [
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Notifications", -1)),
        _createElementVNode("span", _hoisted_8, [
          _createElementVNode("span", {
            class: "marked-all",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.markAllAsRead && _ctx.markAllAsRead(...args)))
          }, "Mark all as read"),
          (_openBlock(), _createElementBlock("svg", {
            class: "close-icon",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("use", { "xlink:href": _hoisted_1 }, null, -1)
          ])))
        ])
      ]),
      _createElementVNode("ul", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: _normalizeClass({ 'selected': item.key === _ctx.selectedTab }),
            onClick: ($event: any) => (_ctx.selectedTab = item.key)
          }, [
            (item.icon)
              ? (_openBlock(), _createElementBlock("svg", _hoisted_11, [
                  _createElementVNode("use", {
                    "xlink:href":  require('@/assets/images/sprites/common-sprite.svg') + item.icon
                  }, null, 8, _hoisted_12)
                ]))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(item.name) + " ", 1),
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "badge" }, "2", -1))
          ], 10, _hoisted_10))
        }), 128))
      ])
    ]),
    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "day" }, "TODAY", -1)),
    _createElementVNode("ul", _hoisted_13, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (item, index) => {
        return _createElementVNode("li", {
          class: _normalizeClass(["notify-item", { 'marked': _ctx.todayMarkedItems.includes(index) }]),
          key: index,
          onClick: ($event: any) => (_ctx.toggleMarked(index, 'todayMarkedItems'))
        }, [
          _createElementVNode("span", _hoisted_15, [
            (_ctx.todayMarkedItems.includes(index))
              ? (_openBlock(), _createElementBlock("svg", _hoisted_16, _cache[7] || (_cache[7] = [
                  _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1)
                ])))
              : (_openBlock(), _createElementBlock("svg", _hoisted_17, _cache[8] || (_cache[8] = [
                  _createElementVNode("use", { "xlink:href": _hoisted_3 }, null, -1)
                ])))
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "notify-info" }, [
            _createElementVNode("span", { class: "notify-title" }, "Kanar LLC created new"),
            _createElementVNode("span", { class: "notify-time" }, "10min ago")
          ], -1)),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "category" }, [
            _createElementVNode("a", null, "Quote Request #124")
          ], -1)),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "request" }, [
            _createElementVNode("svg", { class: "icon" }, [
              _createElementVNode("use", { "xlink:href": _hoisted_4 })
            ]),
            _createElementVNode("svg", { class: "icon" }, [
              _createElementVNode("use", { "xlink:href": _hoisted_5 })
            ])
          ], -1))
        ], 10, _hoisted_14)
      }), 64))
    ]),
    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "day" }, "YESTERDAY", -1)),
    _createElementVNode("ul", _hoisted_18, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (item, index) => {
        return _createElementVNode("li", {
          class: _normalizeClass(["notify-item", { 'marked': _ctx.yesterdayMarkedItems.includes(index) }]),
          key: index,
          onClick: ($event: any) => (_ctx.toggleMarked(index, 'yesterdayMarkedItems'))
        }, [
          _createElementVNode("span", _hoisted_20, [
            (_ctx.yesterdayMarkedItems.includes(index))
              ? (_openBlock(), _createElementBlock("svg", _hoisted_21, _cache[12] || (_cache[12] = [
                  _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1)
                ])))
              : (_openBlock(), _createElementBlock("svg", _hoisted_22, _cache[13] || (_cache[13] = [
                  _createElementVNode("use", { "xlink:href": _hoisted_3 }, null, -1)
                ])))
          ]),
          _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"notify-info\" data-v-4a85a7a4><span class=\"notify-title\" data-v-4a85a7a4>Kanar LLC created new</span><span class=\"notify-time\" data-v-4a85a7a4>20.12.2022 - 18:22</span></div><div class=\"category\" data-v-4a85a7a4><a data-v-4a85a7a4>Quote Request #124</a></div><div class=\"request\" data-v-4a85a7a4><svg class=\"icon\" data-v-4a85a7a4><use xlink:href=\"" + _hoisted_4 + "\" data-v-4a85a7a4></use></svg><svg class=\"icon\" data-v-4a85a7a4><use xlink:href=\"" + _hoisted_5 + "\" data-v-4a85a7a4></use></svg></div>", 3))
        ], 10, _hoisted_19)
      }), 64))
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("button", {
        class: "clear-all btn secondary",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearAll && _ctx.clearAll(...args)))
      }, "Clear All")
    ])
  ], 32)), [
    [_directive_click_away, _ctx.close]
  ])
}