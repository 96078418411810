import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#loading-icon'
const _hoisted_2 = { class: "viewer-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_2, [
    _createElementVNode("img", {
      src: _ctx.source,
      alt: "Photo",
      title: "Photo",
      loading: "lazy",
      onLoad: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isLoad = true))
    }, null, 40, _hoisted_3),
    (!_ctx.isLoad)
      ? (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[1] || (_cache[1] = [
          _createElementVNode("use", { "xlink:href": _hoisted_1 }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}