import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#search-icon'
const _hoisted_2 = _imports_0 + '#notification-icon'
const _hoisted_3 = _imports_0 + '#logout-icon'
const _hoisted_4 = { class: "header" }
const _hoisted_5 = ["badge"]
const _hoisted_6 = { class: "item" }
const _hoisted_7 = { class: "item" }
const _hoisted_8 = { class: "item" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "item" }
const _hoisted_11 = {
  key: 0,
  class: "overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_photo_viewer = _resolveComponent("photo-viewer")!
  const _component_alert_message = _resolveComponent("alert-message")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("section", null, [
        _createElementVNode("div", {
          class: "page-title",
          badge: _ctx.badge
        }, _toDisplayString(_ctx.breadcrumps.at(-1)?.name ?? 'Welcome'), 9, _hoisted_5)
      ]),
      _createElementVNode("section", {
        class: _normalizeClass(["nav", { active: _ctx.menuIsShow }])
      }, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(), _createElementBlock("svg", {
            class: "search",
            onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.$router.go(0);})
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("use", { "xlink:href": _hoisted_1 }, null, -1)
          ])))
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(), _createElementBlock("svg", {
            class: "notification",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleMenu('notification')))
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1)
          ])))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "user",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleMenu('profile')))
          }, [
            (_ctx.user.photo)
              ? (_openBlock(), _createBlock(_component_photo_viewer, {
                  key: 0,
                  class: "photo",
                  source: _ctx.user.photo
                }, null, 8, ["source"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.getUserPhotoPlaceholder()), 1))
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(), _createElementBlock("svg", {
            class: "logout",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
          }, _cache[6] || (_cache[6] = [
            _createElementVNode("use", { "xlink:href": _hoisted_3 }, null, -1)
          ])))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (menu.show)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["toggle-menu", { 'closing': !menu.show }])
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(menu.component), {
                    onClose: ($event: any) => (_ctx.toggleMenu(menu.type))
                  }, null, 40, ["onClose"]))
                ], 2))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ], 2)
    ]),
    (_ctx.menuIsShow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11))
      : _createCommentVNode("", true),
    (_ctx.alertMessage.messages)
      ? (_openBlock(), _createBlock(_component_alert_message, {
          key: 1,
          class: "alert",
          type: _ctx.alertMessage.type,
          title: _ctx.alertMessage.title,
          messages: _ctx.alertMessage.messages
        }, null, 8, ["type", "title", "messages"]))
      : _createCommentVNode("", true)
  ], 64))
}