<template>
  <div class="viewer-container">
    <img :src="source" alt="Photo" title="Photo" loading="lazy" @load="isLoad = true" />
    <svg class="loading" v-if="!isLoad"><use xlink:href="@/assets/images/sprites/common-sprite.svg#loading-icon"></use></svg>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    source: {type: String},
  },
})

export default class PhotoViewer extends Vue {
  isLoad!: boolean;
  source!: string;
  
  data() {
    return {
      isLoad: false,
    } 
  }
}
</script>

<style scoped>
.viewer-container {
  position: relative;
  width: 100%;
  height: 100%;
}
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.loading {
  position: absolute;
  width: 14px;
  height: 14px;
  left: calc(50% - 7px);
  top: calc(50% - 7px);
  animation: rotate linear infinite 1s;
}
</style>