import moment from "moment";

export class DateFormatModel {
  id = 'MMM DD YYYY';
  name = '';

  constructor(data: any = {}) {
    if (data == null) return;
    
    this.id = data.id || 'MMM DD YYYY';
    this.name = moment().format(data.name) || '';
  }
}
