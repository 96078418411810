import ApiServices from "./Api";

export class AccountsServices extends ApiServices {
  private static instance?: AccountsServices;

  public static getInstance(): AccountsServices {
    if (! this.instance) this.instance = new AccountsServices();
    return this.instance;
  }

  public check = (email: string) => this.api.get(`/accounts/check/${email}`);
  public register = (info: registerInfo) => this.api.post('/accounts/register/email', info);
  public verify = (info: verifyInfo) => this.api.put('/accounts/register/email', info);
  public forgotpassword = (info: forgotPasswordInfo) => this.api.post('/accounts/forgotpassword', info);
  public checkresetpassword = (data: string) => this.api.get(`/accounts/resetpassword/${data}`);
  public resetpassword = (data: string, info: resetPasswordInfo) => this.api.put(`/accounts/resetpassword/${data}`, info);
  public compaines = () => this.api.get('/accounts/companies');
  public updateProfile = (info: profileInfo) => this.api.put('/accounts/profile/info', info);
  public upload = (info: uploadInfo) => {
    const data = new FormData();
    Object.keys(info).forEach(key => data.append(key, info[key]));
    return this.api.post('/accounts/profile/photo', data);
  }
  public updatePassword = (info: passwordInfo) => this.api.put('/accounts/password', info);
  public updateLocalization = (info: localizationInfo) => this.api.put('/accounts/localization', info);
}

export interface registerInfo { email: string; password: string; password_confirmation: string; }
export interface verifyInfo { id: number; code: string; }
export interface forgotPasswordInfo { email: string; }
export interface resetPasswordInfo { password: string; password_confirmation: string; }
export interface profileInfo { first_name: string; last_name: string, phone: { code: string, prefix: string, number: string }, language: string }
export interface passwordInfo { current_password: string; password: string, password_confirmation: string }
export interface localizationInfo { language: number, time_zone: string, currency: string, date_format: string }
export interface uploadInfo { photo: File }