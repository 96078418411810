import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#app-logo-text'
const _hoisted_2 = _imports_0 + '#app-logo-image'
const _hoisted_3 = {
  key: 0,
  id: "app-layout"
}
const _hoisted_4 = { class: "main" }
const _hoisted_5 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar_component = _resolveComponent("sidebar-component")!
  const _component_headbar_component = _resolveComponent("headbar-component")!
  const _component_companychooser_component = _resolveComponent("companychooser-component")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["app-loading", {loaded: _ctx.loaded}])
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "container" }, [
            _createElementVNode("div", { class: "text" }, [
              _createElementVNode("svg", null, [
                _createElementVNode("use", { "xlink:href": _hoisted_1 })
              ])
            ]),
            _createElementVNode("div", { class: "logo" }, [
              _createElementVNode("svg", null, [
                _createElementVNode("use", { "xlink:href": _hoisted_2 })
              ])
            ])
          ], -1),
          _createElementVNode("div", { class: "loader" }, null, -1)
        ]), 2))
      : _createCommentVNode("", true),
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.company)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_sidebar_component),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_headbar_component),
                  _createElementVNode("div", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "page", {}, () => [
                      _cache[1] || (_cache[1] = _createTextVNode("Page Container"))
                    ], true)
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_modal_component, {
            show: !_ctx.company
          }, {
            content: _withCtx(() => [
              _createVNode(_component_companychooser_component)
            ]),
            _: 1
          }, 8, ["show"])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}