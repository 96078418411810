<template>
  <div class="container" :class="type">
    <div class="title">
      <svg class="icon"><use :xlink:href="require('@/assets/images/sprites/common-sprite.svg') + icons[type]"></use></svg>
      <span>{{ title ?? titles[type] }}</span>
      <svg class="close" @click="close"><use xlink:href="@/assets/images/sprites/common-sprite.svg#cross-icon"></use></svg>
    </div>
    <div class="message">
      <span v-for="(message, index) in messages" :key="index">{{ message }}</span>
    </div>
    <a v-if="more" href="#" class="see-more">
      <span>SEE MORE</span>
      <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#link-icon"></use></svg>
    </a>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { uuid } from 'vue-uuid';

@Options({
  components: { uuid },
  emits: [ 'update:messages', 'close' ],
  props: {
    type: {
      type: String,
      default: 'error',
      validator: (value: string): boolean => ['info', 'success', 'error'].indexOf(value)!=-1
    },
    title: { type: String },
    more: { type: String },
    messages: { type: Array }
  },
  methods: {
    close() {
      this.$store.dispatch('removeAlertMessage');
      this.$emit('close');
      this.$emit('update:messages', null);
    }
  }
})

export default class AlertMessage extends Vue {
  type!: string;
  title!: string;
  more!: string;
  messages!: any;
  icons!: any;
  titles!: any;
  close!: any;

  data() {
    return {
      icons: { info: '#info-icon', success: '#success-icon', error: '#warning-icon' },
      titles: { info: 'Info', success: 'Success', error: 'Error' }
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  gap: 10px;
  padding: 15px 20px;
  border-radius: 4px;
}
.container.info {
  background-color: var(--primary100);
  border-color: var(--primary200);
  color: var(--primary600);
  stroke: var(--primary600);
}
.container.success {
  background-color: var(--success100);
  border-color: var(--success200);
  color: var(--success600);
  stroke: var(--success600);
}
.container.error {
  background-color: var(--danger100);
  border-color: var(--danger200);
  color: var(--danger600);
  stroke: var(--danger600);
}
.title {
  display: flex;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  gap: 6px;
}
.title .icon {
  width: 18px;
  height: 18px; 
}
.title span {
  font-weight: 500;
}
.info .title span { color: var(--primary600); }
.success .title span { color: var(--success600); }
.error .title span { color: var(--danger600); }
.title .close {
  cursor: pointer;
  width: 18px;
  height: 18px;
  stroke-width: 2.5px;
  margin-left: auto;
}
.message {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.message span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 0 24px;
}
.see-more {
  display: flex;
  gap: 3px;
}
.container.info .see-more {
  color: var(--primary600);
}
.container.success .see-more {
  color: var(--success600);
}
.container.error .see-more {
  color: var(--danger600);
}
.see-more span {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
.see-more svg {
  width: 12px;
  height: 12px;
  stroke-width: 2px;
}
</style>
