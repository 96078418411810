import { CurrencyModel } from "../variables/currency";
import { DateFormatModel } from "../variables/dateformat";
import { TimeZoneModel } from "../variables/timezone";

export class User {
  id = 0;
  email = '';
  first_name = '';
  last_name = '';
  phone: Phone = new Phone();
  language = 1;
  time_zone: TimeZoneModel = new TimeZoneModel;
  date_format: DateFormatModel = new DateFormatModel;
  currency: CurrencyModel = new CurrencyModel;
  photo = null;

  can_update_password = true;
  is_complete = false;

  constructor(data: any = {}) {
    if (data == null) return;

    this.id = data.id || 0;
    this.email = data.email || '';
    this.first_name = data.first_name || '';
    this.last_name = data.last_name || '';
    this.phone = new Phone(data.phone);
    this.language = data.language || 1;
    this.time_zone = new TimeZoneModel(data.time_zone);
    this.date_format = new DateFormatModel(data.date_format);
    this.currency = new CurrencyModel(data.currency);
    this.photo = data.photo || null;
    
    
    this.can_update_password = data.can_update_password ?? true;
    this.is_complete = data.is_complete || false;
  }
}

export class Phone {
  code = 'US';
  prefix = '1';
  number = '';

  constructor(data: any = {}) {
    if (data == null) return;

    this.code = data.code || 'US';
    this.prefix = data.prefix || '1';
    this.number = data.number || '';
  }
}