<template>
  <div class="sidebar" :class="{ min: sub }">
    <div class="main">
      <div class="logo">
        <svg class="app-logo-text"><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-text"></use></svg>
        <svg class="app-logo-image"><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-image"></use></svg>
      </div>
      <div class="nav">
        <template v-for="(route, routeIndex) of nav" :key="routeIndex">
          <router-link :to="route.path" :class="[route.class, { active: $route.matched[0].path == route.path }]">
            <svg class="icon"><use :xlink:href="require('@/assets/images/sprites/common-sprite.svg') + route.icon"></use></svg>
            <span>{{route.name}}</span>
          </router-link>
          <div v-if="route.sub" class="sub-container">
            <div class="sub" :class="{ active: $route.matched[0].path == route.path }">
              <div class="nav">
                <template v-for="(sub, subIndex) of route.sub" :key="subIndex">
                  <router-link :to="sub.path" :class="[sub.class, { active: $route.path == sub.path }]">
                    <svg class="icon"><use :xlink:href="require('@/assets/images/sprites/common-sprite.svg') + sub.icon"></use></svg>
                    <span>{{sub.name}}</span>
                  </router-link>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- <div class="sub-container">
      <template v-for="(main, mIndex) of nav" :key="mIndex">
        <div v-if="main.sub" class="sub" :class="{ active: $route.matched[0].path == main.path }">
          <div class="title">
            <svg><use :xlink:href="require('@/assets/images/sprites/common-sprite.svg') + main.icon"></use></svg>
            <span>{{main.name}}</span>
          </div>
          <div class="nav">
            <template v-for="(route, sIndex) of main.sub" :key="sIndex">
              <router-link :to="route.path" :class="[route.class, { active: $route.path == route.path }]">{{route.name}}</router-link>
            </template>
          </div>
        </div>
      </template>
    </div> -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  computed: {
    sub() {
      return false;
      //return this.nav.find((route) => { return route.path == this.$route.matched[0].path }).sub;
    }
  }
})

export default class Sidebar extends Vue {
  sub!: any;
  nav!: route[]; 
  
  data() {
    return {
      nav: [
        { name: 'Dashboard', path: '/', icon: '#dashboard-icon', class: 'dashboard' },
        { name: 'Shipments', path: '/shipments', icon: '#shipments-icon', class: 'shipments' },
        { name: 'Quotes', path: '/quotes', icon: '#quotes-icon', class: 'quotes' },
        { name: 'Connections', path: '/connections', icon: '#connections-icon', class: 'connections' },
        { name: 'Documents', path: '/documents', icon: '#documents-icon', class: 'documents' },
        { name: 'Settings', path: '/settings', icon: '#settings-icon', class: 'settings', sub: 
          [
            { name: 'Profile', path: '/settings/profile', icon: '#profile-icon', class: 'settings-profile' },
            { name: 'Company', path: '/settings/companyinfo', icon: '#company-icon', class: 'settings-companyinfo' },
            { name: 'Users', path: '/settings/users', icon: '#users-icon', class: 'settings-users' },
            { name: 'Notifications', path: '/settings/notifications', icon: '#notification-icon', class: 'settings-notifications' },
            // { name: 'Localization', path: '/settings/localization', icon: '#dashboard-icon', class: 'settings-localization' },
          ]
        },
        { name: 'Support', path: '/support', icon: '#support-icon', class: 'support' },
      ]
    } 
  }
}

export interface route { name: string, path: string, icon: string, class: string, sub: route[] }
</script>

<style scoped>
.sidebar {
  display: flex;
  transition: all ease-in-out 1s;
  z-index: 97;
}

/* #region Sidebar > Main */
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 225px;
  background-color: var(--white);
  height: 100%;
  padding: 20px 15px;
  filter: drop-shadow(4px 0px 20px rgba(0, 0, 0, 0.15));
  transition: all ease-in-out .25s;
  z-index: 1;
}
.main .app-logo-text {
  display: block;
  width: 100%;
  height: 50px;
}
.main .app-logo-image {
  display: none;
  width: 100%;
  height: 50px;
}
.main .nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10px;
}
.main .nav a {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 48px;
  border-radius: 14px;
  padding: 0 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--neutral800);
  stroke: var(--neutral800);
  text-decoration: none;
  transition: all ease-in-out .25s;
  z-index: 1;
}
.main .nav a[notify]::after {
  display: flex;
  justify-content: center;
  align-items: center;
  content: attr(notify);
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  background-color: var(--primary600);
  color: var(--white);
  border-radius: 50%;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  margin-left: auto;
}
.main .nav a.active,
.main .nav a:hover {
  background-color: var(--neutral100);
  color: var(--primary600);
  stroke: var(--primary600);
}
.main .nav a .icon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
}
.main .nav a span {
  white-space: nowrap;
  overflow: hidden;
}
.main .nav a.support {
  margin-top: auto;
}
/* #endregion Sidebar > Main */

/* #region Siderbar(Min.) > Main */
.min .main {
  width: 86px;
  padding: 20px 10px;
}
.min .app-logo-text {
  display: none;
}
.min .app-logo-image {
  display: block;
}
.min .main a {
  justify-content: center;
  font-size: 12px;
}
.min .main .nav a[notify]::after {
  position: absolute;
  right: 12px;
  top: 6px;
}
.min .main a span {
  display: none;
  position: absolute;
  left: calc(100% + 5px);
  padding: 6px 10px;
  color: var(--neutral800);
  background-color: var(--neutral100);
  border-radius: 10px;
  opacity: .75;
}
.min .main a:hover span {
  display: block;
}
/* #endregion Siderbar(Min.) > Main */

/* #region Siderbar > Sub */
.sub-container {
  height: 100%;
  background-color: var(--primary100);
  padding: 20px 0;
  filter: drop-shadow(4px 0px 20px rgba(0, 0, 0, 0.15));
}
.nav .sub-container {
  height: auto;
  padding: 0;
  filter: none;
  background-color: var(--neutral150);
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  margin-top: -32px;
}
.sub {
  visibility: hidden;
  width: 0;
  height: 0;
  transition: width ease-in-out .5s;
  overflow: hidden;
}
.nav .sub {
  width: auto !important;
}
.sub.active {
  visibility: visible;
  width: 200px;
  height: auto;
}
.sub .title {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--primary600);
  stroke: var(--primary600);
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  padding: 0 40px;
}
.sub .title svg {
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
}
.sub .nav {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.nav .sub .nav {
  gap: 0;
  margin-top: 30px;
}
.sub .nav a {
  display: flex;
  gap: 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--neutral800);
  padding: 10px 40px;
  transition: all ease-in-out .25s;
  text-decoration: none;
  border-bottom: 1px solid var(--neutral150);
  white-space: nowrap;
}
.sub .nav a .icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}
.nav .sub .nav a {
  height: auto;
  border-radius: 0;
  background-color: transparent;
  border: 0;
  padding: 10px 20px;
}
/* .sub .nav a::before {
  content: '-';
} */
.sub .nav a:hover,
.sub .nav a.active {
  color: var(--primary600);
}
/* #endregion Siderbar > Sub */

/* #region Siderbar(Min.) > Main */
@media screen and (max-width: 1200px) {
  .main {
    width: 86px;
    padding: 20px 10px;
  }
  .main .app-logo-text {
    display: none;
  }
  .main .app-logo-image {
    display: block;
  }
  .main a {
    justify-content: center;
    font-size: 12px;
  }
  .main .nav a[notify]::after {
    position: absolute;
    right: 12px;
    top: 6px;
  }
  .main a span {
    display: none;
    position: absolute;
    left: calc(100% + 5px);
    padding: 6px 10px;
    color: var(--neutral800);
    background-color: var(--neutral100);
    border-radius: 10px;
    opacity: .75;
  }
  .main a:hover span {
    display: block;
  }
  
}
/* #endregion Siderbar(Min.) > Main */
</style>