import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "modal-container" }
const _hoisted_2 = { class: "profile" }
const _hoisted_3 = { class: "image" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "info" }
const _hoisted_6 = { class: "name" }
const _hoisted_7 = { class: "email" }
const _hoisted_8 = { class: "companies" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "image" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_photo_viewer = _resolveComponent("photo-viewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.user.photo)
          ? (_openBlock(), _createBlock(_component_photo_viewer, {
              key: 0,
              class: "photo",
              source: _ctx.user.photo
            }, null, 8, ["source"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.getUserPhotoPlaceholder()), 1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.user.first_name) + " " + _toDisplayString(_ctx.user.last_name), 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.user.email), 1)
      ])
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Select a company to proceed.", -1)),
    _createElementVNode("div", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companies, (company, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "item",
          onClick: ($event: any) => (_ctx.setCompany(company))
        }, [
          _createElementVNode("div", _hoisted_10, [
            (company.photo)
              ? (_openBlock(), _createBlock(_component_photo_viewer, {
                  key: 0,
                  class: "photo",
                  source: company.photo
                }, null, 8, ["source"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.getCompanyPhotoPlaceholder(company)), 1))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", null, _toDisplayString(company.name), 1)
          ])
        ], 8, _hoisted_9))
      }), 128))
    ])
  ]))
}