<template>
  <div v-if="$route.matched.length == 1" class="container">
    <svg class="logo"><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-text"></use></svg>
    <div class="nav">
      <a :href="`//app.${baseDomain}/login`">Sign In</a>
      <router-link to="/terms-of-service">Terms Of Service</router-link>
      <router-link to="/privacy-policy">Privacy Policy</router-link>
    </div>
  </div>
  <router-view v-else />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  computed: {
    baseDomain () {
      const host = window.location.host;
      const domain = host.includes('.') ? host.split('.').slice(-2).join('.') : host;
      return domain;
    }
  }
})

export default class Landing extends Vue {
  baseDomain!: string;
}
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .container .logo {
    width: 300px;
    max-width: 50%;
  }
  .container .nav {
    display: flex;
    gap: 15px;
  }
  .container .nav a {
    position: relative;
  }
  .container .nav a + a:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 70%;
    background-color: var(--primary600);
    left: -8px;
    top: 15%;
  }
</style>