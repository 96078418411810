
<template>
  <swiper :modules="modules" :pagination="pagination" :autoplay="autoplay" :loop="true" @swiper="onSwiper" @slideChange="onSlideChange">
    <swiper-slide v-for="item in slider" :key="item.id">
      <div class="item">
        <h1 v-if="item.title">{{ item.title }}</h1>
        <h2 v-if="item.text">{{ item.text }}</h2>
        <img loading="lazy" v-if="item.image" :src="item.image">
      </div>
    </swiper-slide>
    <div class="pagination" :class="pagerPosition"></div>
  </swiper>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Autoplay, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

@Options({
  components: { Swiper, SwiperSlide },
  props: {
    slider: {type: Array},
    pagerPosition: { type: String, default: 'center' },
  },
  methods: {
    onSwiper() {  
      //console.log('onSwiper')
    },
    onSlideChange() {
      //console.log('onSlideChange')
    }
  }
})

export default class Slider extends Vue {
  modules!: any;
  pagination!: any;
  autoplay!: any;
  onSwiper!: any;
  onSlideChange!: any;
  slider!: any
  pagerPosition!: string
  
  data() {
    return {
      pagination: {
        el: '.pagination',
        clickable: true,
        renderBullet: (index: number, className: string) => '<span class="' + className + '"></span>'
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      modules: [Autoplay, Pagination, A11y]
    } 
  }
}
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--neutral800);
  letter-spacing: 0.005em;
  width: 100%;
  height: 100%;
  /* margin: 10px; */
}
.item h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
.item h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pagination {
  display: flex;
  gap: 5px;
  margin: 20px 0;
}
.pagination.left {
  justify-content: flex-start;
}
.pagination.center {
  justify-content: center;
}
.pagination.right {
  justify-content: flex-end;
}
.pagination.bottom {
  position: relative;
  bottom: 50px;
  z-index: 1;
}
.pagination :deep(span) {
  width: 10px;
  height: 10px;
  background-color: var(--carrotOrange);
  border-radius: 5px;
  cursor: pointer;
  transition: all .5s;
  opacity: .25;
}
.pagination :deep(span[class$='-active']) {
  width: 33px;
  opacity: 1;
}
</style>