import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slider_component = _resolveComponent("slider-component")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_onboarding_layout = _resolveComponent("onboarding-layout")!

  return (_openBlock(), _createBlock(_component_onboarding_layout, { title: "Sign Up" }, {
    slider: _withCtx(() => [
      _createVNode(_component_slider_component, {
        class: "slider",
        slider: _ctx.slider,
        pagerPosition: "center bottom"
      }, null, 8, ["slider"])
    ]),
    form: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}