import ApiServices from "./Api";


export class InvitationServices extends ApiServices {
    private static instance?: InvitationServices;
  
    public static getInstance(): InvitationServices {
      if (! this.instance) this.instance = new InvitationServices();
      return this.instance;
    }

    public send = (info) => this.api.post(`/invitation/send`, info);
    public approve = (info) => this.api.post(`/invitation/approve`, info);
    public check = (info) => this.api.post(`/invitation/check`, info);
  }

