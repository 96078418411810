import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#app-logo-text'
const _hoisted_2 = _imports_0 + '#company-icon'
const _hoisted_3 = { class: "modal-container" }
const _hoisted_4 = { style: {"width":"50%","height":"50px","margin":"0 auto"} }
const _hoisted_5 = { class: "profile" }
const _hoisted_6 = { class: "image" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "info" }
const _hoisted_9 = { class: "name" }
const _hoisted_10 = { class: "email" }
const _hoisted_11 = { class: "companies" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "image" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "info" }
const _hoisted_16 = { class: "image" }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_photo_viewer = _resolveComponent("photo-viewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_3, [
    (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[1] || (_cache[1] = [
      _createElementVNode("use", { "xlink:href": _hoisted_1 }, null, -1)
    ]))),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_ctx.user.photo)
          ? (_openBlock(), _createBlock(_component_photo_viewer, {
              key: 0,
              class: "photo",
              source: _ctx.user.photo
            }, null, 8, ["source"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.getUserPhotoPlaceholder()), 1))
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.user.first_name) + " " + _toDisplayString(_ctx.user.last_name), 1),
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.user.email), 1)
      ])
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Select a company to proceed.", -1)),
    _createElementVNode("div", _hoisted_11, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companies, (company, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "item",
          onClick: ($event: any) => (_ctx.setCompany(company))
        }, [
          _createElementVNode("div", _hoisted_13, [
            (company.photo)
              ? (_openBlock(), _createBlock(_component_photo_viewer, {
                  key: 0,
                  class: "photo",
                  source: company.photo
                }, null, 8, ["source"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.getCompanyPhotoPlaceholder(company)), 1))
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("span", null, _toDisplayString(company.name), 1)
          ])
        ], 8, _hoisted_12))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingCompanies, (company, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "item pending"
        }, [
          _createElementVNode("div", _hoisted_16, [
            (company.photo)
              ? (_openBlock(), _createBlock(_component_photo_viewer, {
                  key: 0,
                  class: "photo",
                  source: company.photo
                }, null, 8, ["source"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.getCompanyPhotoPlaceholder(company)), 1))
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("span", null, _toDisplayString(company.name), 1),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "badge" }, "PENDING", -1))
          ])
        ]))
      }), 128)),
      _createElementVNode("div", {
        class: "item new",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/register/company')))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("div", { class: "image" }, [
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_2 })
          ])
        ], -1),
        _createElementVNode("div", { class: "info" }, [
          _createElementVNode("span", null, "Create new company")
        ], -1)
      ]))
    ])
  ]))
}