import { createStore } from 'vuex'
import { AlertMessageModel } from '@/models/commons/alertMessage';

export default createStore({
  state: {
    invitation: localStorage.getItem('invitation') ? JSON.parse(localStorage.getItem('invitation') as string) : null,
    init: [],
    variables: localStorage.getItem('variables') ? JSON.parse(localStorage.getItem('variables') as string) : [],
    authorization: localStorage.getItem('authorization') ? JSON.parse(localStorage.getItem('authorization') as string) : null,
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : null,
    companies: localStorage.getItem('companies') ? JSON.parse(localStorage.getItem('companies') as string) : [],
    companyId: localStorage.getItem('companyId') ?? null,
    redirectUrl: localStorage.getItem('redirectUrl'),
    pendingCompanies: [],
    companyRoles: [],
    alertMessage: new AlertMessageModel()
  },
  getters: {
    checks: state => state.init,
    authorizationIsValid: state => {
      if (!state.authorization) return false;
      const now = new Date();
      const validDate = new Date(state.authorization.created_at);
      validDate.setSeconds(validDate.getSeconds() + state.authorization.expires_in);
      return validDate.getTime()  > now.getTime();
    },
    company: state => {
      return state.companies.find(item => item.id == state.companyId );
    },
    companyUser: (state, getters) => {
      return getters.company.users.find(user => user.user_id == state.user.id);
    },
    role: (state, getters) => {
      return getters.companyUser.role_id;
    },
    claims: (state, getters) => {
      return getters.companyUser.claims;
    },
    variables: state => key => {
      return state.variables.find(variable => variable.key == key).data;
    },
  },
  mutations: {
    setInvitation (state, data) {
      state.invitation = data;
      localStorage.setItem('invitation', JSON.stringify(data));
    },
    removeInvitation (state) {
      state.invitation = null;
      localStorage.removeItem('invitation');
    },
    setInit (state, init) {
      state.init = init;
    },
    setVariables (state, variables) {
      state.variables = variables;
      localStorage.setItem('variables', JSON.stringify(variables));
    },
    setAuthorization (state, authorization) {
      authorization['created_at'] = new Date();
      state.authorization = authorization;
      localStorage.setItem('authorization', JSON.stringify(authorization));
      document.dispatchEvent(new CustomEvent('appLogicticsLoggedIn', { detail: authorization }));
    },
    removeAuthorization (state) {
      state.authorization = null;
      localStorage.removeItem('authorization');
    },
    setUser (state, user) { 
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
      document.dispatchEvent(new CustomEvent('appLogicticsUser', { detail: user }));
    },
    removeUser (state) {
      state.user = null;
      localStorage.removeItem('user');
    },
    setCompanies (state, companies) { 
      state.companies = companies;
      localStorage.setItem('companies', JSON.stringify(companies));
      document.dispatchEvent(new CustomEvent('appLogicticsCompanies', { detail: companies }));
      if (companies.length == 1) {
        const company = companies[0];
        state.companyId = company.id;
        localStorage.setItem('companyId', JSON.stringify(company.id));
      }
      if (state.companyId !== null) {
        document.dispatchEvent(new CustomEvent('appLogicticsCompanySetted', { detail: state.companyId }));
      }
    },
    setCompanyId (state, id) { 
      state.companyId = id;
      localStorage.setItem('companyId', id);
      document.dispatchEvent(new CustomEvent('appLogicticsCompanySetted', { detail: id }));
    },
    updateCompany (state, company) {
      const companies = state.companies.map(item => {
        if (item.id == company.id) item = company;
        return item;
      });
      state.companies = companies;
      localStorage.setItem('companies', JSON.stringify(companies));
      document.dispatchEvent(new CustomEvent('appLogicticsCompanies', { detail: companies }));
    },
    setPendingCompanies (state, pendingCompanies) {
      state.pendingCompanies = pendingCompanies;
    },
    addCompanyUser (state, user) {
      const companies = state.companies.map(company => {
        if (company.id == state.companyId) {
          company.users.push(user);
        }
        return company;
      });
      state.companies = companies;
      localStorage.setItem('companies', JSON.stringify(companies));
    },
    updateCompanyUser (state, user) {
      const companies = state.companies.map(company => {
        if (company.id == state.companyId) {
          company.users = company.users.map(item => {
            if (item.user_id == user.user_id) item = user;
            return item;
          });
        }
        return company;
      });
      state.companies = companies;
      localStorage.setItem('companies', JSON.stringify(companies));
    },
    setCompanyRoles (state, companyRoles) {
      state.companyRoles = companyRoles;
    },
    removeCompanies (state) {
      state.companies = [];
      localStorage.removeItem('companies');
    },
    clearStorage (state) {
      state.authorization = null;
      state.user = null;
      state.companies = [];
      localStorage.removeItem('authorization');
      localStorage.removeItem('user');
      localStorage.removeItem('companies');
      document.dispatchEvent(new CustomEvent('appLogicticsLoggedOut', { detail: {} }));
    },
    setRedirectUrl (state, url) {
      state.redirectUrl = url;
      localStorage.setItem('redirectUrl', url);
    },
    removeRedirectUrl (state) {
      state.redirectUrl = null;
      localStorage.removeItem('redirectUrl');
    },
    setAlertMessage (state, data) {
      state.alertMessage = data;
    },
    removeAlertMessage (state) {
      state.alertMessage = new AlertMessageModel();
    }
  },
  actions: {
    setInvitation({ commit }, data) { commit('setInvitation', data); },
    removeInvitation({ commit }) { commit('removeInvitation'); },
    setInit({ commit }, init) { commit('setInit', init) },
    setVariables({ commit }, variables) { commit('setVariables', variables); },
    setAuthorization({ commit }, authorization) { commit('setAuthorization', authorization); },
    removeAuthorization({ commit }) { commit('removeAuthorization'); },
    setUser({ commit }, user) { commit('setUser', user); },
    removeUser({ commit }) { commit('removeUser'); },
    setCompanies({ commit }, companies) { commit('setCompanies', companies); },
    setCompanyId({ commit }, id) { commit('setCompanyId', id); },
    updateCompany({ commit }, company) { commit('updateCompany', company); },
    addCompanyUser( { commit }, user) { commit('addCompanyUser', user); },
    updateCompanyUser( { commit }, user) { commit('updateCompanyUser', user); },
    setPendingCompanies({ commit }, pendingCompanies) { commit('setPendingCompanies', pendingCompanies); },
    setCompanyRoles({ commit }, companyRoles) { commit('setCompanyRoles', companyRoles); },
    removeCompanies({ commit }) { commit('removeCompanies'); },
    clearStorage({ commit }) { commit('clearStorage'); },
    setRedirectUrl({ commit }, url) { commit('setRedirectUrl', url); },
    removeRedirectUrl({ commit }) { commit('removeRedirectUrl'); },
    setAlertMessage({ commit }, data) { commit('setAlertMessage', data); },
    removeAlertMessage({ commit }) { commit('removeAlertMessage'); },
  },
  modules: {
  }
})
