import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#down-arrow-icon'
const _hoisted_2 = _imports_0 + '#company-icon'
const _hoisted_3 = { class: "image" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "info" }
const _hoisted_6 = { class: "name" }
const _hoisted_7 = { class: "email" }
const _hoisted_8 = { class: "companies" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "image" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "info" }
const _hoisted_13 = { class: "image" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_photo_viewer = _resolveComponent("photo-viewer")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", { fold: _ctx.isFold }]),
    onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["esc"]))
  }, [
    _createElementVNode("div", {
      class: "profile",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isFold = !_ctx.isFold))
    }, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.user.photo)
          ? (_openBlock(), _createBlock(_component_photo_viewer, {
              key: 0,
              class: "photo",
              source: _ctx.user.photo
            }, null, 8, ["source"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.getUserPhotoPlaceholder()), 1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.user.first_name) + " " + _toDisplayString(_ctx.user.last_name), 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.user.email), 1)
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("svg", { class: "arrow" }, [
        _createElementVNode("use", { "xlink:href": _hoisted_1 })
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companies, (_company, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["item", { active: _company.id == _ctx.company.id }]),
          onClick: ($event: any) => (_ctx.setCompany(_company))
        }, [
          _createElementVNode("div", _hoisted_10, [
            (_company.photo)
              ? (_openBlock(), _createBlock(_component_photo_viewer, {
                  key: 0,
                  class: "photo",
                  source: _company.photo
                }, null, 8, ["source"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.getCompanyPhotoPlaceholder(_company)), 1))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", null, _toDisplayString(_company.name), 1)
          ])
        ], 10, _hoisted_9))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingCompanies, (company, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "item pending"
        }, [
          _createElementVNode("div", _hoisted_13, [
            (company.photo)
              ? (_openBlock(), _createBlock(_component_photo_viewer, {
                  key: 0,
                  class: "photo",
                  source: company.photo
                }, null, 8, ["source"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.getCompanyPhotoPlaceholder(company)), 1))
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("span", null, _toDisplayString(company.name), 1),
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "badge" }, "PENDING", -1))
          ])
        ]))
      }), 128)),
      _createElementVNode("div", {
        class: "item new",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/register/company')))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("div", { class: "image" }, [
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_2 })
          ])
        ], -1),
        _createElementVNode("div", { class: "info" }, [
          _createElementVNode("span", null, "Create new company")
        ], -1)
      ]))
    ])
  ], 34)), [
    [_directive_click_away, _ctx.close]
  ])
}